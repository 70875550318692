<template>
  <v-snackbar
    class="base-alert"
    :value="hasError"
    :top="true"
    :timeout="timeout"
    max-width="712px"
    :transition="transition"
    outlined
    :color="iconColor"
    @input="snackbarClose"
  >
    <v-icon
      :color="iconColor"
      class="mr-2"
    >
      {{ icon }}
    </v-icon>

    <span class="base-alert__message">
      {{ message }}
    </span>

    <template
      v-if="dismissible"
      v-slot:action="{ attrs }"
    >
      <v-btn
        icon
        :color="iconColor"
        v-bind="attrs"
        @click="closeAlertEmit"
      >
        <v-icon>
          $closeCircle
        </v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'BaseAlert',
  props: {
    type: {
      type: String,
      default: 'error',
      validator: (value) => {
        return ['info', 'success', 'warning', 'error'].includes(value)
      }
    },
    message: {
      type: String,
      default: 'Ocorreu um erro durante operação!'
    },
    countDown: {
      type: Number,
      default: 5000
    },
    dismissible: {
      type: Boolean,
      default: true,
      validator: (value) => {
        return [true, false].includes(value)
      }
    },
    autoClose: {
      type: Boolean,
      default: true,
      validator: (value) => {
        return [true, false].includes(value)
      }
    }
  },
  computed: {
    hasError () {
      return  !!this.message
    },
    icon () {
      switch (this.type) {
        case 'success':
          return this.$vuetify.icons.values.successCircleOutline
        case 'info':
          return this.$vuetify.icons.values.infoCircleOutline
        case 'warning':
          return this.$vuetify.icons.values.alertWarningtOutline
        default:
          return this.$vuetify.icons.values.closeCircleOutline
      }
    },
    iconColor () {
      return this.type
    },
    transition () {
      return this.hasError ? 'scroll-y-transition' : 'scroll-y-reverse-transition'
    },
    timeout () {
      return this.autoClose ? this.countDown : '-1'
    }
  },
  methods: {
    closeAlertEmit () {
      this.$emit('close-alert', false)
    },
    snackbarClose () {
      this.closeAlertEmit()
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.v-snack__content) {
  letter-spacing: unset !important;
}
</style>
